<template>
  <!-- 选择企业基础资料 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1200px"
    :showFooter="false"
    title="选择企业基础资料"
  >
    <!-- 查询条件 -->
    <BaseForm
      :componentList="businessFormConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '70px',
        'label-position': 'left'
      }"
      class="formStyle"
      :showBtns="false"
    ></BaseForm>
    <!--  -->
    <base-button
      type="text"
      label=""
      icon="iconfont iconzhongxinshangchuan"
      btnType="upload"
      :accept="accept"
      style="margin: 0px 10px 10px;float: right;"
      action="#"
      :http-request="upload"
    />
    <!-- 表格 -->
    <BaseTable
      :columns="businessColumns"
      :showPage="false"
      :tableAttrs="{
        stripe: true,
        data: tableData
      }"
      :selectedRows.sync="selectedRows"
      :selfChangeHeight="200"
      ref="tableData"
      :webPage="true">
      <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      <template slot="attachment" slot-scope="scope">
        <file-button-group
          :item="scope.row"
          :index="scope.$index"
          :scope="scope"
          :noUpdate="true"
          @previewShow="previewShow"
          @deleteTemplate="deleteTemplate"
          @dowload='downloadFile'>
        </file-button-group>
      </template>
    </BaseTable>
    <template slot="footer">
      <base-button @click="submit" label='确 定'></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false"
      ></base-button>
    </template>
    <!-- 文件预览 -->
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="count" />
  </base-dialog>
</template>
<script>
import baseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import { downFile } from '@/utils/devUtils'
import { fileAPi, downAPi } from '@/api/fileApi'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { businessFormConfig, businessColumns } from '../utils/add-config'
import FileButtonGroup from './file-button-group.vue'
import { allSupportFileTypes } from '@/components/pre-view/pre-view.js'
import PreView from '@/components/pre-view/pre-view.vue'
import { supplierApi, supplierFileApi } from '@/api/companyApi'
export default {
  name: 'previewDialog',
  components: { BaseDialog, baseButton, BaseForm, BaseTable, FileButtonGroup, PreView },
  props: {
    visible: Boolean,
    showIndex: Number,
    enterpriseId: String,
    accept: { // 上传文件格式，默认上传.doc,.docx,.pdf
      type: String,
      default: '.doc,.docx,.pdf'
    },
    fileDetailList: Array, // 选中的数据
    documentId: String, // WSF_FILE_0169 企业章程及修正案、 WSF_FILE_0170 历次批复及补充流程
    typeOption: { // 资料类型下拉数据
      type: Array,
      default: () => [
        { name: '企业章程及修正案', id: 'WSF_FILE_0169' },
        { name: '历次批复及补充流程', id: 'WSF_FILE_0170' }
      ]
    },
    showSigned: { // 是否显示已经盖章列 非必填
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      queryParas: {
        configSetCode: 'F_GYS_BASEFILE'
      },
      count: 0,
      fileId: '',
      fileType: '',
      tableData: [],
      selectedRows: [],
      options: []
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.getTableData()
        }
      }
    },
    tableData: {
      handler (val) {
        if (val.length > 0) {
          const that = this
          that.$refs.tableData.clearSelection()
          this.$nextTick(() => {
            // 回显选中的选项
            that.$refs.tableData.clearSelection()
            for (let i = 0; i < that.tableData.length; i++) {
              for (let j = 0; j < that.fileDetailList.length; j++) {
                if (that.tableData[i].fileName === that.fileDetailList[j].fileName && that.tableData[i].fileId === that.fileDetailList[j].fileId) {
                  that.$refs.tableData.toggleRowSelection(that.tableData[i], true)
                }
              }
            }
          })
        }
      },
      deep: true
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    businessFormConfig () {
      return businessFormConfig(this)
    },
    businessColumns () {
      return businessColumns(this)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.gysList()
    })
  },
  methods: {
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.options = res.data
        }
      })
    },
    // 获取文件列表数据
    getTableData () {
      this.queryParas.configItemCode = this.documentId
      this.queryParas.enterpriseId = this.enterpriseId
      supplierFileApi.getsupRecordList(this.queryParas).then(res => {
        this.tableData = res.data
      })
    },
    // 上传文件
    async upload (data) {
      if (data.file.name.length > 80) {
        return this.warning('文件名称小于80字符')
      }
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(data.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(data.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const res = await this.UploadFile(data)
      const obj = {
        configItemCode: this.documentId,
        configSetCode: 'F_GYS_BASEFILE',
        enterpriseId: this.enterpriseId,
        fileId: res.keyId,
        fileName: res.fileName,
        fileByte: res.fileByte
      }
      supplierFileApi.saveFileold(obj).then((res) => {
        this.success('文件上传成功')
        this.getTableData()
      })
    },
    // 调用文件服务上传文件
    async UploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 预览模板文件
    async previewShow (data) {
      this.fileId =
        !data.pdfFileId || data.pdfFileId === '0'
          ? data.fileId
          : data.pdfFileId
      this.fileType = data.fileName ? data.fileName.split('.').pop() : ''
      this.fileType =
        !data.pdfFileId || data.pdfFileId === '0' ? this.fileType : 'pdf'
      this.fileType = this.fileType.toLowerCase()
      if (!this.fileType || !allSupportFileTypes.includes(this.fileType)) {
        this.warning(`不能预览的文件类型：[${this.fileType}]`)
        return
      }
      this.count++
    },
    // 删除文件
    deleteTemplate (row, type, index, i) {
      // 追加文件时删除没有保存数据
      if (row.addFile) {
        this.tableData[index].fileListDetailVOS.splice(i, 1)
        return
      }
      this.$confirm(type === 4 ? '是否删除当前项?' : '是否删除文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          supplierFileApi
            .delsupRecordList({ keyId: row.keyId })
            .then((res) => {
              this.success('删除成功')
              this.getTableData()
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch(() => {})
    },
    downloadFile (data) {
      if (data.fileId) {
        const params = { keyId: data.fileId }
        downAPi.download(params).then(res => {
          downFile(res)
        }).catch(e => {
          this.error(e)
        })
      }
    },
    submit () {
      if (this.selectedRows && this.selectedRows.length > 10) {
        return this.warning('附件最多只能有10个')
      }
      this.$emit('selectFileList', this.selectedRows, this.showIndex)
      this.currentVisible = false
    }
  }
}
</script>
